import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';

export const radioProtecaoGuard: CanActivateFn = () => {
  const gestaoAcesso = inject(GestaoAcessoService);
  return gestaoAcesso.validaAcessos({
    key: 'nomGrupo',
    value: 'radioprotecao'
  });
};
