import 'moment/locale/pt-br';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FUNCIONALIDADES_PERFIL } from '@core/constants/injectionTokens/funcionalidadesPerfil';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { ObterFuncionalidadesPerfilRequestBody } from '@core/models/services/perfil/obterFuncionalidadesPerfilRequestBody';
import { ObterFuncionalidadesPerfilResponseRegistro } from '@core/models/services/perfil/obterFuncionalidadesPerfilResponseRegistro';
import { obterToken } from '@core/utils/obterToken';
import { obterTokenPayload } from '@core/utils/obterTokenPayload';
import { environment } from '@environment';
import { from } from 'rxjs';

import { AppModule } from './app/app.module';

const inicializar = () => {
  const token = obterToken()?.replaceAll('"', '');
  const codPerfil = obterTokenPayload()?.codPerfil;

  from(
    fetch(`${environment.baseURL}Perfil/ObterFuncionalidadesPerfil`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        codPerfil,
        paginar: false
      } as ObterFuncionalidadesPerfilRequestBody)
    }).then(r => r.json())
  ).subscribe({
    next: ({ data }) => {
      let useValue =
        new PaginacaoResponseData<ObterFuncionalidadesPerfilResponseRegistro>();
      if (data) {
        useValue = data;
      }

      platformBrowserDynamic([{ provide: FUNCIONALIDADES_PERFIL, useValue }])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
    },
    error: () => {
      platformBrowserDynamic([
        {
          provide: FUNCIONALIDADES_PERFIL,
          useValue:
            new PaginacaoResponseData<ObterFuncionalidadesPerfilResponseRegistro>()
        }
      ])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
    }
  });
};

inicializar();
